import React, { useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDownload,
  faInfoCircle,
  faCertificate,
} from '@fortawesome/free-solid-svg-icons';
import {
  Switch,
  Tab,
  TabList,
  Tabs,
  TabIndicator,
  TabPanel,
  TabPanels,
  Tooltip,
  Box,
  Spinner,
} from '@chakra-ui/react';
import Filters from './Filters';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import '../../../../assets/css/DataTable.css';
import axios from 'axios';
import { useToast } from '@chakra-ui/react';
import moment from 'moment';
// import { filter } from '@chakra-ui/system';
const DataTable = ({ data, docIds }) => {
  const toast = useToast();
  const [gridApi, setGridApi] = useState(null);
  const [loadingRow, setLoadingRow] = useState({});
  const arrayData = Array.isArray(data) ? data : [data];
  const updatedArrayData = arrayData.map((item) => ({
    ...item,
    // claim_amount: item.total_claim_amt + item.total_tax,
    transaction_details: {
      tot_no: item.tot_no,
      mfac_name: item.mfac_name,
      off_invoice_per: item.off_invoice_per,
      trans_item_code_count: item.trans_item_code_count,
      trans_supp_code_count: item.trans_supp_code_count,
      trans_tax_type: item.trans_tax_type,
    },
  }));
  const flattenedData = updatedArrayData.flatMap((item, parentIndex) =>
    item.details.map((detail, detailIndex) => ({
      ...detail,
      transaction_details: item.transaction_details,
      total_claim_amt: item.total_claim_amt,
      detail_index: `${parentIndex}_${detailIndex}`,
    })),
  );
  console.log(flattenedData);
  flattenedData.forEach((item) => {
    if (item.supp_code && item.supp_code.startsWith('C')) {
      console.log(
        `Supp Code: ${item.supp_code}, Supp Name: ${item.c_supp_name}`,
      );
    }
  });

  const [tPostData, setTPostData] = useState(flattenedData);

  const [filteredData, setFilteredData] = useState([data]);

  const handleFilterChange = (filterText) => {
    if (Array.isArray(data)) {
      const filteredData = data.filter((item) => {
        return (
          // item.transactionNo.toLowerCase().includes(filterText.toLowerCase()) ||
          item.trans_tax_type.toLowerCase().includes(filterText.toLowerCase())
          // item.status.toLowerCase().includes(filterText.toLowerCase())
        );
      });
      setFilteredData(filteredData);
    } else {
      console.error('Data is not an array:', data);
    }
  };

  const calculateDaysFromFirstOfMonth = () => {
    const today = new Date();
    const firstOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

    const differenceInMilliseconds = today - firstOfMonth;
    const differenceInDays = Math.floor(
      differenceInMilliseconds / (1000 * 60 * 60 * 24),
    );

    return differenceInDays + 1;
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  // const handleSwitchChange = (rowIndex) => {
  //   const rowData = arrayData[0].details[rowIndex];
  //   const mfacName = arrayData[0].mfac_name;
  //   const rowDataIndex = { rowData, rowIndex, mfacName};
  //   axios.post('http://nmrserver.wellnessforever.co.in/api/nmr/netsuite', rowDataIndex)
  //     .then(response => {
  //       console.log('Success:', response.data);
  //       if (gridApi) {
  //         gridApi.refreshCells();
  //       }
  //     })
  //     .catch(error => {
  //       console.error('Error:', error);
  //     });

  //   console.log('Switch toggled for row:', rowIndex);
  // };

  const handleSwitchChange = (rowDetailsData, rowIndex) => {
    setLoadingRow((prev) => ({ ...prev, [rowIndex]: true }));
    console.log('rowDetailsData', rowDetailsData, rowIndex);

    const rowDetailsIndex = rowDetailsData.detail_index;
    const parentIndex = parseInt(rowDetailsIndex.split('_')[0], 10);
    const mfacName = arrayData[parentIndex].mfac_name;
    const docId = docIds[parentIndex];
    const rowDataIndex = { rowDetailsData, rowDetailsIndex, mfacName, docId };

    console.log('Switch toggled for row:', rowDetailsIndex);
    console.log('Switch toggled for row:', rowDataIndex);

    axios
      .post('http://nmrserver.wellnessforever.co.in/api/nmr/netsuite', rowDataIndex)
      .then((response) => {
        const updatedFields = response.data;
        const rowNode = gridApi.getRowNode(rowIndex);

        if (rowNode) {
          rowNode.updateData({
            ...rowNode.data,
            status: updatedFields.status,
            trans_no: updatedFields.trans_no,
          });
          toast({
            title: 'Success',
            description: 'Transaction updated successfully.',
            status: 'success',
            duration: 5000,
            isClosable: true,
            position: 'top-right',
          });
        }
      })
      .catch((error) => {
        const rowNode = gridApi.getRowNode(rowIndex);
        console.log(rowNode);

        // console.error('Error:', error);

        toast({
          title: 'Error',
          description: 'Failed to update transaction.',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      })
      .finally(() => {
        setLoadingRow((prev) => ({ ...prev, [rowIndex]: false }));
      });
  };

  const transactionRenderer = ({ value }) => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-start',
          lineHeight: '1.2em',
          paddingTop: '12px',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {/* <span style={{ marginRight: '8px', fontWeight: 'bold', fontSize: '12px', color: '#1a202c' }}>
            {value.tot_no}
          </span> */}

          <div style={{ fontSize: '11px', marginTop: '4px' }}>
            {value.mfac_name}
          </div>

          <Tooltip
            label={
              <Box as="table" borderRadius="lg" bg="#8B83BA" color="white">
                <Box as="tr">
                  <Box as="td" padding="0 8px">
                    Margin: {value.off_invoice_per}%
                  </Box>
                  <Box as="td" padding="0 8px">
                    Items: {value.trans_item_code_count}
                  </Box>
                  <Box as="td" padding="0 8px">
                    Suppliers: {value.trans_supp_code_count}
                  </Box>
                </Box>
                <Box as="tr" borderTop="1px solid white">
                  <Box as="td" colSpan="3" padding="0 8px">
                    Transaction Type: {value.trans_tax_type}
                  </Box>
                </Box>
              </Box>
            }
            fontSize="12px"
            placement="auto-start"
            bg="#ffffff00"
            boxShadow="none"
            // color="gray.800"
          >
            <span
              style={{ marginLeft: '5px', cursor: 'pointer', color: '#4A5568' }}
            >
              <FontAwesomeIcon icon={faInfoCircle} />
            </span>
          </Tooltip>
        </div>
      </div>
    );
  };

  const reportRenderer = ({ value }) => (
    <a
      href={value}
      download
      style={{ textDecoration: 'none', color: '#1a202c' }}
    >
      <button
        style={{
          background: 'none',
          border: 'none',
          cursor: 'pointer',
          padding: '0',
        }}
        aria-label="Download Report"
      >
        <FontAwesomeIcon icon={faDownload} size="lg" />
      </button>
    </a>
  );

  const statusRenderer = ({ value }) => {
    let color;
    let backgroundColor;

    switch (value) {
      case 'Rejected':
        color = '#ff0000';
        backgroundColor = '#ffe6e6';
        break;
      case 'Approved':
        color = '#52c41a';
        backgroundColor = '#f6ffed';
        break;
      case 'Pending':
        color = '#faad14';
        backgroundColor = '#fffbe6';
        break;
      default:
        color = '#8c8c8c';
        backgroundColor = '#f0f0f0';
        break;
    }

    return (
      <div
        style={{
          display: 'inline-flex',
          alignItems: 'center',
          backgroundColor,
          borderRadius: '12px',
          padding: '4px 8px',
        }}
      >
        <span
          style={{
            height: '8px',
            width: '8px',
            borderRadius: '50%',
            backgroundColor: color,
            marginRight: '8px',
          }}
        ></span>
        <span style={{ color, whiteSpace: 'nowrap', lineHeight: '1' }}>
          {value}
        </span>
      </div>
    );
  };

  // const switchRenderer = (params) => {
  //   return (
  //     <Switch
  //       isChecked={params.value}
  //       onChange={(e) => {
  //         const isChecked = e.target.checked;
  //         if (isChecked) {
  //           handleSwitchChange(params.node.rowIndex);
  //         }
  //       }}
  //       size="sm"
  //     />
  //   );
  // };

  const switchRenderer = (params) => {
    const rowIndex = params.node.rowIndex;
    const isPending = params.data.status === 'Pending';

    return (
      <Box>
        {loadingRow[rowIndex] ? (
          <Spinner size="sm" color="blue.500" />
        ) : (
          <Switch
            isChecked={params.value}
            onChange={() => handleSwitchChange(params.data, rowIndex)}
            size="sm"
            isDisabled={!isPending}
          />
        )}
      </Box>
    );
  };

  const gstCellRenderer = (params) => {
    const gstValue = params.value;
    const customerRegNo = params.data.customer_reg_number;
    let badgeLetter;
    let badgeColor;
    let top;
    let left;

    if (customerRegNo.startsWith('27')) {
      top = '-2px';
      left = '-9px';
      badgeColor = '#B197FC';
      badgeLetter = 'S';
    } else {
      top = '-2px';
      left = '-8px';
      badgeColor = '#FFD43B';
      badgeLetter = 'I';
    }

    return (
      <span style={{ marginTop: '3px' }}>
        {gstValue}
        <FontAwesomeIcon
          icon={faCertificate}
          style={{ color: badgeColor, marginLeft: '5px', fontSize: '14px' }}
        />
        <span
          style={{
            color: 'white',
            fontSize: '8px',
            fontWeight: 'bold',
            position: 'relative',
            top: top,
            left: left,
          }}
        >
          {badgeLetter}
        </span>
      </span>
    );
  };

  const daysRenderer = (params) => {
    const dateFormat = 'YYYY-MM-DD';
    const dDate = moment(params.value, dateFormat);

    const firstDayOfMonth = moment().startOf('month');

    const daysDifference = dDate.diff(firstDayOfMonth, 'days') + 1;

    return daysDifference;
  };

  const DashboardDefs = [
    // {
    //   headerCheckboxSelection: true,
    //   checkboxSelection: true,
    //   headerName: '',
    //   width: 50,
    //   sortable: false,
    //   filter: false,
    //   resizable: false,
    //   suppressHeaderMenuButton: true,
    // },
    {
      headerName: 'MANUFACTURER NAME',
      field: 'transaction_details',
      cellRenderer: transactionRenderer,
      width: 290,
    },
    {
      headerName: 'NO OF TOT',
      field: '',
      width: 100,
      valueGetter: (params) => {
        return Array.isArray(params.data.details)
          ? params.data.details.length
          : 0;
      },
    },
    {
      headerName: 'TRANSACTION TYPE',
      field: 'trans_type',
      width: 180,
      filter: true,
    },
    { headerName: 'TRANSACTION COUNT', field: 'trans_bill_count', width: 200 },
    {
      headerName: 'DAYS',
      field: 'd_date',
      width: 90,
      cellRenderer: daysRenderer,
    },
    {
      headerName: 'CLAIM AMOUNT',
      field: '',
      width: 190,
      valueGetter: (params) => {
        const baseClaimAmt = params.data.total_claim_amt || 0;
        const taxSum = Array.isArray(params.data.details)
          ? params.data.details.reduce(
              (sum, detail) => sum + (detail.total_tax || 0),
              0,
            )
          : 0;

        const totalClaimAmt = baseClaimAmt + taxSum;

        return totalClaimAmt.toLocaleString('en-IN', {
          style: 'currency',
          currency: 'INR',
        });
      },
      type: 'rightAligned',
      // filter: true
    },
    {
      headerName: 'REPORT',
      field: 'tot_report',
      cellRenderer: reportRenderer,
      width: 130,
    },
  ];
  const ToPostDefs = [
    {
      headerName: 'MANUFACTURER NAME',
      field: 'transaction_details',
      cellRenderer: transactionRenderer,
      width: 215,
      // pinned: 'left',
    },
    {
      headerName: 'BILL TO',
      field: 'c_supp_name',
      width: 260,
      filter: 'agMultiColumnFilter',
      // pinned: 'left'
    },
    {
      headerName: 'TAXABLE AMOUNT',
      field: 'total_claim_amt',
      width: 130,
      type: 'rightAligned',
      valueFormatter: (params) => {
        if (params.value != null) {
          return params.value.toLocaleString('en-IN', {
            style: 'currency',
            currency: 'INR',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
        }
        return '';
      },
    },
    {
      headerName: 'GST',
      field: 'mfac_c_gst_code',
      width: 90,
      // cellRenderer: (params) => {
      //   const gstValue = params.value;
      //   const customerRegNo = params.data.customer_reg_number;
      //   let badgeLetter;
      //   let badgeColor;
      //   let top;
      //   let left;

      //   if (customerRegNo.startsWith('27')) {
      //     top = '-2px';
      //     left = '-9px';
      //     badgeColor = '#B197FC'
      //     badgeLetter = 'S';
      //   } else {
      //     top = '-2px';
      //     left = '-8px';
      //     badgeColor = '#FFD43B'
      //     badgeLetter = 'I';
      //   }

      //   return (
      //     <span style={{ marginTop: '3px' }}>
      //       {gstValue}
      //       <FontAwesomeIcon icon={faCertificate} style={{ color: badgeColor, marginLeft: '5px', fontSize: '14px' }} />
      //       <span style={{
      //         color: 'white',
      //         fontSize: '8px',
      //         fontWeight: 'bold',
      //         position: 'relative',
      //         top: top,
      //         left: left,
      //       }}>{badgeLetter}</span>
      //     </span>
      //   );

      // }
      cellRenderer: gstCellRenderer,
    },
    {
      headerName: 'CLAIM AMOUNT',
      width: 150,
      valueGetter: (params) => {
        return (params.data.total_claim_amt + params.data.total_tax).toFixed(2);
      },
      valueFormatter: (params) => {
        if (params.value != null) {
          return Number(params.value).toLocaleString('en-IN', {
            style: 'currency',
            currency: 'INR',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
        }
        return '';
      },
      type: 'rightAligned',
    },
    // {
    //   headerName: 'TDS', field: '', width: 60,
    //   valueGetter: (params) => {
    //     return params.data.tds ? params.value : 0;
    //   }
    // },
    // {
    //   headerName: 'TDS AMOUNT', field: '', width: 105,
    //   type: 'rightAligned',
    //   valueGetter: (params) => {
    //     const tdsPer = params.data.tds ? params.data.tds / 100 : 0;
    //     return (params.data.total_amount * tdsPer).toFixed(2);
    //   },
    //   valueFormatter: (params) => {
    //     if (params.value != null) {
    //       return Number(params.value).toLocaleString('en-IN', {
    //         style: 'currency',
    //         currency: 'INR',
    //         minimumFractionDigits: 2,
    //         maximumFractionDigits: 2
    //       });
    //     }
    //     return '';
    //   }
    // },
    // {
    //   headerName: 'TOTAL TAX',
    //   field: 'total_tax',
    //   width: 120,
    //   valueFormatter: (params) => {
    //     return params.value.toFixed(2);
    //   },
    //   type: 'rightAligned',
    //   // filter: true
    // },
    // {
    //   headerName: 'CLAIM AMOUNT',
    //   // field: 'total_amount',
    //   width: 150,
    //   type: 'rightAligned',
    //   valueGetter: (params) => {
    //     const billValue = (params.data.total_amount + params.data.total_tax);
    //     const tdsPer = params.data.tds ? params.data.tds / 100 : 0;
    //     const tdsAmount = params.data.total_amount * tdsPer;
    //     return (billValue - tdsAmount).toFixed(2);
    //   },
    //   valueFormatter: (params) => {
    //     if (params.value != null) {
    //       return Number(params.value).toLocaleString('en-IN', {
    //         style: 'currency',
    //         currency: 'INR',
    //         minimumFractionDigits: 2,
    //         maximumFractionDigits: 2
    //       });
    //     }
    //     return '';
    //   }
    // },
    {
      headerName: 'ACTION',
      // field: '',
      cellRenderer: switchRenderer,
      width: 80,
    },
    {
      headerName: 'STATUS',
      field: 'status',
      cellRenderer: statusRenderer,
      width: 120,
      filter: true,
    },
    {
      headerName: 'REF NO',
      field: 'trans_no',
      // cellRenderer: reportRenderer,
      width: 147,
    },
  ];

  const getRowHeight = (params) => {
    // if (expandedRows[params.node.rowIndex]) {
    //   return 150;
    // }
    return 50;
  };

  const defaultColDef = {
    sortable: true,
    resizable: true,
    editable: false,
  };

  return (
    <div>
      <Tabs position="relative" variant="unstyled">
        <TabList>
          <Tab>Dashboard</Tab>
          <Tab>To Post</Tab>
        </TabList>
        <TabIndicator
          mt="-1.5px"
          height="2px"
          bg="blue.500"
          borderRadius="1px"
        />
        <TabPanels>
          <TabPanel>
            {/* <Filters onFilterChange={handleFilterChange} /> */}
            <div
              className="ag-theme-alpine custom-grid"
              style={{ height: 600, width: '100%', borderRadius: '20px' }}
            >
              <AgGridReact
                columnDefs={DashboardDefs}
                rowData={updatedArrayData}
                rowSelection="multiple"
                pagination={true}
                paginationPageSize={10}
                getRowHeight={getRowHeight}
                frameworkComponents={{
                  transactionRenderer: transactionRenderer,
                  statusRenderer: statusRenderer,
                  reportRenderer: reportRenderer,
                  gstCellRenderer: gstCellRenderer,
                  daysRenderer: daysRenderer,
                }}
              />
            </div>
          </TabPanel>
          <TabPanel>
            {/* <Filters /> */}
            <div
              className="ag-theme-alpine custom-grid"
              style={{ height: 620, width: '100%', borderRadius: '20px' }}
            >
              <AgGridReact
                columnDefs={ToPostDefs}
                rowData={tPostData}
                rowSelection="multiple"
                pagination={true}
                paginationPageSize={10}
                getRowHeight={getRowHeight}
                frameworkComponents={{
                  transactionRenderer: transactionRenderer,
                  statusRenderer: statusRenderer,
                  reportRenderer: reportRenderer,
                  switchRenderer: switchRenderer,
                }}
                onGridReady={onGridReady}
              />
            </div>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  );
};

export default DataTable;
