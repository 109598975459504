import React from 'react';
import { Box, Text, Icon } from '@chakra-ui/react';

const Cards = ({ icon, label, value, iconBg }) => {
  return (
    <Box
      position="relative"
      borderWidth="1px"
      borderRadius="lg"
      p={4}
      bg="white"
      boxShadow="sm"
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      justifyContent="center"
      height="120px"
      width="150px"
      // bg="gray.50"
    >
      <Box
        position="absolute"
        top="-20px"
        right="-20px"
        bg={iconBg}
        borderRadius="full"
        p={3} // Increased padding around the icon
        display="flex"
        justifyContent="center"
        alignItems="center"
        color="white"
        boxSize={12} // Increased size of the icon container
        boxShadow="md"
      >
        <Icon as={icon} boxSize={5} />
      </Box>
      <Text fontSize="sm" color="gray.500" mb={2}>
        {label}
      </Text>
      <Text fontSize="l" fontWeight="bold" mt={2}>
        {value}
      </Text>
    </Box>
  );
};

export default Cards;
