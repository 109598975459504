import React, { useState } from 'react';
import { Flex, Input, IconButton } from '@chakra-ui/react';
// import { FaFilter } from 'react-icons/fa';

const Filters = ({ onFilterChange }) => {
  const [filterText, setFilterText] = useState('');

  const handleInputChange = (e) => {
    const value = e.target.value;
    setFilterText(value);
    onFilterChange(value); 
  };

  return (
    <Flex 
      alignItems="center" 
      bg="white" 
      p={4} 
      boxShadow="sm"
      borderTopLeftRadius="20px"
      borderTopRightRadius="20px"
    >
      {/* <IconButton icon={<FaFilter />} aria-label="Filter" mr={2} /> */}
      <Input 
        placeholder="Search Trans No, Type, Status" 
        size="md" 
        w="300px" 
        mr={2}
        value={filterText}
        onChange={handleInputChange}
      />
    </Flex>
  );
};

export default Filters;
