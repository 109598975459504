import React, { useState, useEffect } from 'react';
import { Box, SimpleGrid, Container, Flex, IconButton } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import Cards from './components/Cards';
import calculateCardData from './variables/cardsData';
import DataTable from './components/DataTable';
import { fetchManageDocsData } from './variables/tableData';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import NodataFoundImg from 'assets/img/NoDataFound.png';
import '../../../assets/css/App.css';

const Dashboard = () => {
  const [tableData, setTableData] = useState(null);
  const [docIds, setDocId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [cardsData, setCardsData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const getTableData = async () => {
      try {
        const { jsonData, docIds } = await fetchManageDocsData();
        console.log(jsonData, docIds);

        setTableData(jsonData);
        setDocId(docIds);

        setLoading(false);
      } catch (error) {
        console.error('Error fetching table data:', error);
        setError(error);
        setLoading(false);
      }
    };

    getTableData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const data = await calculateCardData();
      setCardsData(data);
    };
    fetchData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  const handleLogout = async () => {
    console.log('Logging out...');
    localStorage.removeItem('token');
    localStorage.removeItem('roleName');
    localStorage.removeItem('userName');
    try {
      const response = await axios.post(
        'http://nmrserver.wellnessforever.co.in/api/auth/logout',
      );
      if (response.status === 200) {
        navigate('/');
      } else {
        setError('Logout failed. Please try again.');
      }
    } catch (error) {
      console.error('Error logging out:', error);
      setError('An error occurred during logout. Please try again.');
    }
  };
  if (error) {
    return (
      <div>
        <div className="noDataFoundImgContainer">
          <img src={NodataFoundImg} alt="" />
          <button onClick={handleLogout}>Back to Login</button>
        </div>
      </div>
    );
  }

  return (
    <Flex direction="column" minHeight="100vh" bg="#F2F0F9">
      <Flex
        as="nav"
        bg="transparent"
        p={4}
        align="center"
        justify="flex-end"
        // borderBottom="1px solid #e2e8f0"
      >
        <IconButton
          icon={<FontAwesomeIcon icon={faSignOutAlt} />}
          aria-label="Logout"
          variant="link"
          color="red"
          _hover={{ color: 'gray.300' }}
          onClick={handleLogout}
        />
      </Flex>
      <Flex justify="center" align="center" flex="1" direction="column">
        <Container maxW="container.xl" p={8} m={6} pt={0}>
          <SimpleGrid
            columns={{ sm: 2, md: 3, lg: 3, xl: 5 }}
            spacing="45px"
            justifyContent="center"
          >
            {cardsData.map((card, index) => (
              <Flex key={index} justify="center" align="center">
                <Cards
                  label={card.label}
                  value={card.value}
                  icon={card.icon}
                  iconBg={card.iconBg}
                  // maxW="400px"
                  size="lg"
                />
              </Flex>
            ))}
          </SimpleGrid>
          <Box mt={8}>
            <DataTable data={tableData} docIds={docIds} />
          </Box>
        </Container>
      </Flex>
    </Flex>
  );
};

export default Dashboard;
