// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  font-family: 'DM Sans', sans-serif;
}

option {
  color: black;
}

.noDataFoundImgContainer {
  width: 100%;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.noDataFoundImgContainer > button {
  background-color: #422afb; /* Default background color */
  color: white; /* Text color */
  border: none; /* No border */
  padding: 10px 20px; /* Padding for size */
  font-size: 16px; /* Font size */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor */
  transition: background-color 0.3s ease; /* Smooth hover transition */
}

.noDataFoundImgContainer > button:hover {
  background-color: #e2e8f0; /* Background color on hover */
  color: #422afb; /* Text color on hover */
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/App.css"],"names":[],"mappings":"AACA;EACE,kCAAkC;AACpC;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,WAAW;EACX,aAAa;EACb,YAAY;EACZ,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;AACxB;AACA;EACE,yBAAyB,EAAE,6BAA6B;EACxD,YAAY,EAAE,eAAe;EAC7B,YAAY,EAAE,cAAc;EAC5B,kBAAkB,EAAE,qBAAqB;EACzC,eAAe,EAAE,cAAc;EAC/B,kBAAkB,EAAE,oBAAoB;EACxC,eAAe,EAAE,mBAAmB;EACpC,sCAAsC,EAAE,4BAA4B;AACtE;;AAEA;EACE,yBAAyB,EAAE,8BAA8B;EACzD,cAAc,EAAE,wBAAwB;EACxC,iBAAiB;AACnB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');\r\nbody {\r\n  font-family: 'DM Sans', sans-serif;\r\n}\r\n\r\noption {\r\n  color: black;\r\n}\r\n\r\n.noDataFoundImgContainer {\r\n  width: 100%;\r\n  display: flex;\r\n  height: 100%;\r\n  justify-content: center;\r\n  align-items: center;\r\n  flex-direction: column;\r\n}\r\n.noDataFoundImgContainer > button {\r\n  background-color: #422afb; /* Default background color */\r\n  color: white; /* Text color */\r\n  border: none; /* No border */\r\n  padding: 10px 20px; /* Padding for size */\r\n  font-size: 16px; /* Font size */\r\n  border-radius: 5px; /* Rounded corners */\r\n  cursor: pointer; /* Pointer cursor */\r\n  transition: background-color 0.3s ease; /* Smooth hover transition */\r\n}\r\n\r\n.noDataFoundImgContainer > button:hover {\r\n  background-color: #e2e8f0; /* Background color on hover */\r\n  color: #422afb; /* Text color on hover */\r\n  font-weight: bold;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
